
import {
  defineComponent, ref, watchEffect, PropType,
} from 'vue';
import { XIcon } from '@heroicons/vue/outline';
import BaseCard from '../UI/BaseCard.vue';
import {
  LanguageCode,
  LanguagePairKey,
  NotificationMessageType,
} from '@/modules/types';
import options from '@/modules/options';
import { useNotifier, useStore } from '@/modules/injectUtil';
import { userDictShouldBeDisplayed, validateUserDict } from '@/modules/dictUtil';

export default defineComponent({
  components: {
    BaseCard,
    XIcon,
  },
  emits: {
    dismiss: () => true,
  },
  props: {
    languagePairKey: {
      type: String as PropType<LanguagePairKey<LanguageCode, LanguageCode>>,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const notifier = useNotifier();
    const { state, methods } = useStore();

    // Object containing all info about the dictionaries of the current language pair
    const dictionaries = state.dictionaries[props.languagePairKey];

    if (!dictionaries) {
      throw new Error(
        `The state does not contain information about dictionaries for the language pair ${props.languagePairKey}. The language pair key might be incorrect.`,
      );
    }

    /**
     * USER DEFINED DICTIONARY ---------------------------------------------------------------------
     */

    const newUserDictRaw = ref(dictionaries.userDictRaw);

    let lastEditTime = Date.now();
    // This will be updated immediately bu the next method.
    const inputIsValid = ref(true);
    watchEffect(() => {
      const thisEditTime = Date.now();
      lastEditTime = thisEditTime;

      let ok = true;

      try {
        validateUserDict(newUserDictRaw.value);
      } catch (error: any) {
        ok = false;

        // If they have stopped making edits and the input is still invalid,
        // tell them what is wrong.
        setTimeout(() => {
          if (thisEditTime !== lastEditTime) {
            // They edited it again in the meantime.
            return;
          }

          notifier.dismissCurrentNotification();
          notifier.notify(error.message, {
            messageType: NotificationMessageType.alert,
          });
        }, 2000);
      }

      // If the input is invalid, immediately provide visual feedback to the user.
      inputIsValid.value = ok;
      if (ok) {
        notifier.dismissCurrentNotification();
      }

      // This will only parse the valid lines. Thus, we can parse every time the input changes.
      methods.updateUserDictRaw(props.languagePairKey, newUserDictRaw.value);
    });

    /**
     * OTHER DICTIONARY SELECTION ------------------------------------------------------------------
     */

    const newSelectedDictionaries = ref(dictionaries.selectedDicts);
    // This function will be called if the user selects/deselects a dictionary.
    function updateDictionarySelection(index: number, selected: boolean) {
      const changeVal = dictionaries.availableDicts[index];

      if (!changeVal) {
        console.warn(
          'Selected dictionary cannot be found.', 'Index:', index, 'Selected:', selected, 'Value:', changeVal,
        );
        return;
      }

      if (selected) {
        if (options.allowMultipleDictSelection) {
          newSelectedDictionaries.value.push(changeVal);
        } else {
          newSelectedDictionaries.value = [changeVal];
        }
      } else {
        newSelectedDictionaries.value = newSelectedDictionaries.value.filter(
          (v) => v !== changeVal,
        );
      }

      methods.updateSelectedDicts(
        props.languagePairKey,
        newSelectedDictionaries.value,
      );
    }

    /**
     * MISC ----------------------------------------------------------------------------------------
     */

    function dismiss() {
      emit('dismiss');
    }

    return {
      dismiss,
      newUserDictRaw,
      updateDictionarySelection,
      availableDictionaries: dictionaries.availableDicts,
      newSelectedDictionaries,
      inputIsValid,
      showUserDict: userDictShouldBeDisplayed(props.languagePairKey),
    };
  },
});

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-list"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="#000000"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="9" y1="6" x2="20" y2="6" />
    <line x1="9" y1="12" x2="20" y2="12" />
    <line x1="9" y1="18" x2="20" y2="18" />
    <line x1="5" y1="6" x2="5" y2="6.01" />
    <line x1="5" y1="12" x2="5" y2="12.01" />
    <line x1="5" y1="18" x2="5" y2="18.01" />
  </svg>
</template>

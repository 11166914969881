<template>
  <div class="bg-white rounded-lg shadow-md">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style></style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "bg-gray-300 p-1 rounded-full flex space-x-2 transition-all ease-in-out",
  style: {"min-width":"8rem"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuOptions, (option, key) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["w-full rounded-full px-6 border-gray-600 border-4 border-opacity-0 focus:outline-none text-sm font-bold text-gray-500", {
        'bg-white shadow-md text-gray-700': key === _ctx.selectedIndex,
        'hover:border-opacity-20': key !== _ctx.selectedIndex,
      }]),
        key: key,
        onClick: ($event: any) => (_ctx.updateSelectedIndex(key))
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.upperCase ? String(option).toUpperCase() : option), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
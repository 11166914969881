<template>
  <button
    class="
      h-7
      w-7
      rounded-md
      flex
      justify-center
      items-center
      hover:bg-gray-200
      transition-all
    "
    :class="{ 'bg-gray-200 hover:bg-gray-300': active }"
  >
    <slot class="flex justify-center items-center"></slot>
  </button>
</template>

<script>
export default {
  props: ['active'],
};
</script>


import {
  ref, watchEffect, defineComponent, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { TranslateIcon, XIcon } from '@heroicons/vue/solid';
import { BookOpenIcon } from '@heroicons/vue/outline';

import { JSONContent } from '@tiptap/core';
import { log } from 'loglevel';
import { parseEditorContent } from '../modules/editorUtil';
import { requestTranslationData } from '../modules/serverUtil';

import BaseSelector from '../components/UI/BaseSelector.vue';
import BaseButton from '../components/UI/BaseButton.vue';
import BaseDialog from '../components/UI/BaseDialog.vue';
import BaseCard from '../components/UI/BaseCard.vue';
import ElementLabel from '../components/start_view/ElementLabel.vue';
import Tiptap from '../components/editor/Tiptap.vue';
import TheDictionaryOptions from '@/components/start_view/TheDictionaryOptions.vue';
import { NotificationMessageType } from '@/modules/types';
import {
  authenticationIsEnabled,
  getLanguagePairKey,
  onlyUniqueValues,
} from '@/modules/otherUtil';
import options from '@/modules/options';
import { useNotifier, useStore } from '@/modules/injectUtil';
import { userDictShouldBeDisplayed } from '@/modules/dictUtil';
import demoSrcText from '@/demoHelpers/demoSrcText';

export default defineComponent({
  name: 'Home',
  components: {
    SrcLangSelector: BaseSelector,
    trgLangSelector: BaseSelector,
    ElementLabel,
    BaseButton,
    BaseDialog,
    BaseCard,
    TranslateIcon,
    Tiptap,
    BookOpenIcon,
    TheDictionaryOptions,
    XIcon,
  },
  setup() {
    const notifier = useNotifier();
    const { state, methods } = useStore();
    const router = useRouter();

    /**
     * LANGUAGE SELECTION --------------------------------------------------------------------------
     */

    const selectedSrcLangCode = ref(state.srcLang);
    const selectedTrgLangCode = ref(state.trgLang);

    if (
      // Selected language pair is not available
      options.availableLanguagePairs.filter(
        (x) => x.from === selectedSrcLangCode.value
          && x.to === selectedTrgLangCode.value,
      ).length === 0
    ) {
      const firstAvailableLanguagePair = options.availableLanguagePairs[0];
      if (firstAvailableLanguagePair === undefined) {
        throw new Error('No available language pair was specified in options.ts');
      }

      selectedSrcLangCode.value = firstAvailableLanguagePair.from;
      selectedTrgLangCode.value = firstAvailableLanguagePair.to;
    }

    const languagePairKey = computed(() => getLanguagePairKey(
      selectedSrcLangCode.value,
      selectedTrgLangCode.value,
    ));

    const srcLangs = ref(
      options.availableLanguagePairs
        .map((pair) => pair.from)
        .filter((onlyUniqueValues)),
    );
    const trgLangs = ref(
      options.availableLanguagePairs
        .map((pair) => pair.to)
        .filter(onlyUniqueValues),
    );

    // Index of the selected source language in the srcLangs array
    const selectedSrcLangIndex = ref(
      srcLangs.value.findIndex((el) => el === selectedSrcLangCode.value),
    );

    // Index of the selected target language in the trgLangs array
    const selectedTrgLangIndex = ref(
      trgLangs.value.findIndex((el) => el === selectedTrgLangCode.value),
    );

    // Update the list of selectable targetLanguages if the selected source language is updated.
    watchEffect(() => {
      log('Updating available target languages');
      trgLangs.value = options.availableLanguagePairs
        .filter((pair) => pair.from === selectedSrcLangCode.value)
        .map((pair) => pair.to)
        .filter(onlyUniqueValues);
    });

    // Listen to changes in the source language selection
    watchEffect(() => {
      try {
        selectedSrcLangCode.value = srcLangs.value[selectedSrcLangIndex.value];
        methods.updateState({
          srcLang: selectedSrcLangCode.value,
        });
      } catch (error: any) {
        notifier.notify(error.message, {
          messageType: NotificationMessageType.error,
        });
      }
    });

    // Listen to changes in the target language selection
    watchEffect(() => {
      try {
        selectedTrgLangCode.value = trgLangs.value[selectedTrgLangIndex.value];
        methods.updateState({
          trgLang: selectedTrgLangCode.value,
        });
      } catch (error: any) {
        notifier.notify(error.message, {
          messageType: NotificationMessageType.error,
        });
      }
    });

    /**
     * EDITOR --------------------------------------------------------------------------------------
     */

    // const editorContent = ref<JSONContent>({ type: 'doc' });
    const editorContent = ref<JSONContent>(demoSrcText);

    Object.assign(editorContent.value, state.originalEditorContent);

    // Listen to updates in the editor
    watchEffect(() => {
      methods.updateState({
        originalEditorContent: editorContent.value,
      });
    });

    /**
     * TRANSLATION ---------------------------------------------------------------------------------
     */

    const showTranslationDialog = ref(false);
    let latestTranslationId = state.translationSessionId;

    async function startTranslation() {
      try {
        const thisTranslationId = state.translationSessionId;
        latestTranslationId = thisTranslationId;

        showTranslationDialog.value = true;

        const { textSegments, structure } = await parseEditorContent(
          editorContent.value,
        );

        methods.updateState({
          originalStructure: structure,
          originalTextSegments: textSegments,
        });

        if (!selectedSrcLangCode.value || !selectedTrgLangCode.value) {
          throw new Error('Source or target language is undefined.');
        }

        const response = await requestTranslationData(
          textSegments,
          selectedSrcLangCode.value,
          selectedTrgLangCode.value,
          state.translationSessionId,
          state.user,
          state.dictionaries[languagePairKey.value].userDictJSON,
          state.dictionaries[languagePairKey.value].selectedDicts,
        );

        if (
          // User has dismissed the dialog and therefore aborted the translation.
          !showTranslationDialog.value
          // The response corresponds to a previous translation request.
          || latestTranslationId !== thisTranslationId
        ) {
          return;
        }

        methods.updateState({
          textSegmentsFromApi: response,
        });

        showTranslationDialog.value = false;
        router.push({
          name: 'SuggestionsView',
        });
      } catch (error: any) {
        showTranslationDialog.value = false;
        notifier.notify(`Translation failed: ${error.message}`, {
          messageType: NotificationMessageType.error,
        });
      }
    }

    function abortTranslation() {
      showTranslationDialog.value = false;
    }

    /**
     * DICTIONARIES --------------------------------------------------------------------------------
     */

    const dictionaryOptionsAreVisible = ref(false);

    function showDictionaryOptions() {
      dictionaryOptionsAreVisible.value = true;
    }

    function hideDictionaryOptions() {
      dictionaryOptionsAreVisible.value = false;
    }

    const dictOptionsAvailable = computed(() => userDictShouldBeDisplayed(languagePairKey.value)
      || state.dictionaries[languagePairKey.value].availableDicts.length > 0);

    /**
     * MISC ----------------------------------------------------------------------------------------
     */

    const characterLimit = options.enableCharacterLimit
      ? options.characterLimit
      : undefined;

    const demoNotificationIsVisible = ref(false);

    setTimeout(() => {
      demoNotificationIsVisible.value = true;
    }, 700);

    return {
      languagePairKey,
      srcLangs,
      trgLangs,
      selectedSrcLangIndex,
      selectedTrgLangIndex,
      startTranslation,
      editorContent,
      abortTranslation,
      showTranslationDialog,
      authenticationIsEnabled,
      dictionaryOptionsAreVisible,
      showDictionaryOptions,
      hideDictionaryOptions,
      characterLimit,
      dictOptionsAvailable,
      enableSpellcheck: options.enableSpellcheck,
      demoNotificationIsVisible,
    };
  },
});


import { defineComponent, PropType, ref } from 'vue';
import { NotificationMessageType } from '@/modules/types';
import BaseNotification from './UI/BaseNotification.vue';
import { Notifier } from '@/modules/Notifier';

export default defineComponent({
  components: {
    BaseNotification,
  },
  props: {
    notifier: {
      type: Object as PropType<Notifier>,
      required: true,
    },
  },
  setup(props) {
    const showNotification = ref(false);
    const notificationType = ref(NotificationMessageType.alert);
    const notificationMessage = ref('');

    if (!props.notifier) {
      throw new Error('Notifier is undefined.');
    }

    props.notifier.onNewNotification((msg, options) => {
      notificationMessage.value = msg;
      showNotification.value = true;
      notificationType.value = options.messageType;
    });

    props.notifier.onDismissNotification(() => {
      showNotification.value = false;
    });

    return {
      showNotification,
      notificationType,
      notificationMessage,
    };
  },
});

import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_editor_menu = _resolveComponent("editor-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-full relative overflow-hidden transform", { 'pb-10 delay-150': _ctx.showMenu }])
  }, [
    _createVNode(_component_editor_content, {
      editor: _ctx.editor,
      spellcheck: _ctx.enableSpellcheck,
      class: _normalizeClass(["h-full overflow-y-auto editor p-8", { unfocused: !_ctx.showMenu }])
    }, null, 8, ["editor", "spellcheck", "class"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showMenu)
          ? (_openBlock(), _createBlock(_component_editor_menu, {
              key: 0,
              class: "absolute w-full bottom-0 left-0 h-10",
              editor: _ctx.editor,
              onMouseenter: _ctx.handleMenuFocus,
              onMouseleave: _ctx.handleMenuBlur,
              characterLimit: _ctx.characterLimit
            }, null, 8, ["editor", "onMouseenter", "onMouseleave", "characterLimit"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}
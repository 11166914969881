
import { CheckIcon, ExclamationIcon, XIcon } from '@heroicons/vue/solid';
import { defineComponent, PropType } from 'vue';
import { NotificationMessageType } from '@/modules/types';

export default defineComponent({
  components: {
    CheckIcon,
    ExclamationIcon,
    XIcon,
  },
  emits: {
    dismiss: () => true,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    messageType: {
      type: Number as PropType<NotificationMessageType>,
      default: () => NotificationMessageType.alert,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      NotificationMessageType,
    };
  },
});

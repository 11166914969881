import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-606139db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "h-44 w-screen absolute left-0 top-0 from-black from bg-gradient-to-b opacity-10",
  style: {"z-index":"200000"}
}
const _hoisted_2 = { class: "bg-white max-w-xl rounded-3xl flex items-center p-2 space-x-4 pr-5 shadow-2xl justify-center" }
const _hoisted_3 = { class: "text-gray-700 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_icon = _resolveComponent("check-icon")!
  const _component_exclamation_icon = _resolveComponent("exclamation-icon")!
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "drop-down" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss'))),
              class: "fixed inset-0 left-0 top-0 flex justify-center items-start pt-8",
              style: {"z-index":"200001"}
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["rounded-full p-2", {
              // Success
              'bg-mtc_medium_aquamarine-400 text-mtc_medium_aquamarine-900':
                _ctx.messageType === _ctx.NotificationMessageType.success,

              // Alert
              'bg-yellow-400 text-yellow-900':
                _ctx.messageType === _ctx.NotificationMessageType.alert,

              // Error
              'bg-red-400 text-red-900':
                _ctx.messageType === _ctx.NotificationMessageType.error,
            }])
                }, [
                  (_ctx.messageType === _ctx.NotificationMessageType.success)
                    ? (_openBlock(), _createBlock(_component_check_icon, {
                        key: 0,
                        class: "w-5 h-5"
                      }))
                    : (_ctx.messageType === _ctx.NotificationMessageType.alert)
                      ? (_openBlock(), _createBlock(_component_exclamation_icon, {
                          key: 1,
                          class: "w-5 h-5"
                        }))
                      : (_openBlock(), _createBlock(_component_x_icon, {
                          key: 2,
                          class: "w-5 h-5"
                        }))
                ], 2),
                _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.message), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
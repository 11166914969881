import scrollIntoView from 'smooth-scroll-into-view-if-needed';

function scrollToElementsWithClass(cssClass: string) {
  const elements = document.getElementsByClassName(cssClass);
  Array.from(elements).forEach(async (el) => {
    const isSuggestionCard = el.classList.contains('suggestion-card');
    await scrollIntoView(el, {
      behavior: 'smooth',
      scrollMode: isSuggestionCard ? 'always' : 'if-needed',
      block: isSuggestionCard ? 'start' : 'center',
    });
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  scrollToElementsWithClass,
};

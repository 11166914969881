import { inject } from 'vue';
import { storeKey } from '@/store';
import { notifierKey } from './Notifier';

function useStore() {
  const store = inject(storeKey);
  if (store === undefined) {
    throw new Error('Store is undefined.');
  }
  return store;
}

function useNotifier() {
  const n = inject(notifierKey);
  if (n === undefined) {
    throw new Error('Notifier is undefined.');
  }
  return n;
}

export { useStore, useNotifier };

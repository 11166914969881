import { browserLocalPersistence } from '@firebase/auth';
import log, { LogLevelDesc } from 'loglevel';
import { getEnvVarOrFallback } from './environmentUtil';
import { EnvironmentVariable, LanguageCode } from './types';

log.setLevel(
  getEnvVarOrFallback<LogLevelDesc>(EnvironmentVariable.logLevel, 'TRACE'),
);

const devFirebaseConfig = {
  apiKey: 'AIzaSyBn033H95JRoJL1LNHGUXVUMM6cVHZtB8s',
  appId: '1:461990907139:web:b34f70551ca6daeaa8ed36',
  messagingSenderId: '461990907139',
  projectId: 'mtc-dev',
  authDomain: 'mtc-dev.firebaseapp.com',
  storageBucket: 'mtc-dev.appspot.com',
};

const prodFirebaseConfig = {
  apiKey: 'AIzaSyDs-5vDpSPiIH7M7XMl3f-shmzBhgg0wvE',
  appId: '1:176136282412:web:3c277bd4572057deef4c89',
  messagingSenderId: '176136282412',
  projectId: 'mtc-production',
  authDomain: 'mtc-production.firebaseapp.com',
  storageBucket: 'mtc-production.appspot.com',
};

const options = {
  /**
   * GENERAL OPTIONS -------------------------------------------------------------------------------
   */

  // Generate a fake machine translation and fake suggestions instead of contacting the backend.
  useFakeApi: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.enableFakeApi,
    true,
  ),

  // This can be used to simulate that the translation takes some time. Value in milliseconds.
  fakeApiDelay: getEnvVarOrFallback<number>(
    EnvironmentVariable.fakeApiDelay,
    500,
  ),

  // In percent divided by 100
  maxSuggestionRatio: getEnvVarOrFallback<number>(
    EnvironmentVariable.maxSuggestionRatio,
    0.2,
  ),

  baseUrlApi: getEnvVarOrFallback<string>(EnvironmentVariable.backendURL, ''),

  // Controls the character limit for the translation.
  enableCharacterLimit: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.enableCharacterLimit,
    true,
  ),

  characterLimit: getEnvVarOrFallback<number>(
    EnvironmentVariable.characterLimit,
    10000,
  ),

  saveAllTextSegments: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.sendAllTextSegments,
    true,
  ),

  /**
   * LANGUAGE OPTIONS ------------------------------------------------------------------------------
   */

  availableLanguagePairs: [
    {
      from: LanguageCode.German,
      to: LanguageCode.English,
      allowUserDict: true,
      availableDicts: ['trans_dict.de_en'],
    },
  ] as {
    from: LanguageCode;
    to: LanguageCode;
    allowUserDict: Boolean;
    availableDicts: string[];
  }[],

  // If this is selected, the user can select multiple dictionaries at the same time.
  // Otherwise, they can only select one dictionary per translation.
  allowMultipleDictSelection: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.allowMultipleDictSelection,
    true,
  ),

  enableSpellcheck: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.enableSpellcheck,
    true,
  ),

  /**
   * AUTHENTICATION OPTIONS ------------------------------------------------------------------------
   */

  environment: getEnvVarOrFallback<string>(
    EnvironmentVariable.environment,
    'dev',
  ).toLowerCase(),

  firebaseConfig: getEnvVarOrFallback<string>(EnvironmentVariable.environment, 'dev').toLowerCase() === 'prod' ? prodFirebaseConfig : devFirebaseConfig,

  enableAuthInDevelopment: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.enableAuthInDev,
    false,
  ),

  enableAuthInProduction: getEnvVarOrFallback<boolean>(
    EnvironmentVariable.enableAuthInProd,
    false,
  ),

  // Controls when the user will be logged out automatically
  loginPersistence: browserLocalPersistence,
};

export default options;


import { defineComponent, ref } from 'vue';
import { getAuth, signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';
import BaseCard from '@/components/UI/BaseCard.vue';
import BaseButton from '@/components/UI/BaseButton.vue';
import { NotificationMessageType } from '@/modules/types';
import { useNotifier } from '@/modules/injectUtil';

export default defineComponent({
  components: {
    BaseCard,
    BaseButton,
  },
  setup() {
    const auth = getAuth();
    const loggedOut = ref(false);
    const router = useRouter();
    const notifier = useNotifier();

    signOut(auth)
      .then(() => {
        loggedOut.value = true;
      })
      .catch(() => {
        notifier.notify(
          'Something went wrong. Please reload the page to try again.',
          {
            messageType: NotificationMessageType.error,
          },
        );
      });

    function buttonClicked() {
      router.push({
        name: 'AuthenticationView',
      });
    }

    return {
      loggedOut,
      buttonClicked,
    };
  },
});

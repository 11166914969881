import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence } from '@firebase/auth';
import App from './App.vue';
import getRouter from './router';
import './styles/index.css';
import { storeKey, store } from './store/index';
import options from './modules/options';
import { Notifier, notifierKey } from './modules/Notifier';

// Firebase authentication
initializeApp(options.firebaseConfig);
const auth = getAuth();
setPersistence(auth, options.loginPersistence);

// Update user in application store whenever the auth state changes
auth.onAuthStateChanged((user) => {
  store.methods.updateState({
    user,
  });
});

const app = createApp(App);

// Make the store and the notifier accessible from everywhere
app.provide(storeKey, store);
app.provide(notifierKey, new Notifier());

app.use(getRouter({ store }));

// Connect Vue app to DOM
app.mount('#app');

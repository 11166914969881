
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BaseCard from '@/components/UI/BaseCard.vue';
import BaseButton from '@/components/UI/BaseButton.vue';

export default defineComponent({
  components: {
    BaseCard,
    BaseButton,
  },
  setup() {
    const router = useRouter();

    function buttonClicked() {
      router.push({
        path: '/',
      });
    }

    return {
      buttonClicked,
    };
  },
});

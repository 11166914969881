
import { defineComponent, ref, watch } from 'vue';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';
import BaseCard from '@/components/UI/BaseCard.vue';
import BaseButton from '@/components/UI/BaseButton.vue';
import { NotificationMessageType } from '@/modules/types';
import { useNotifier } from '@/modules/injectUtil';

export default defineComponent({
  components: {
    BaseCard,
    BaseButton,
  },
  props: {
    targetPath: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const email = ref('');
    const emailInput = ref<any>(null);
    const password = ref('');
    const passwordInput = ref<any>(null);
    const buttonIsEnabled = ref(false);
    const auth = getAuth();
    const router = useRouter();

    const notifier = useNotifier();

    watch([email, password], () => {
      if (
        emailInput.value.checkValidity()
        && passwordInput.value.checkValidity()
      ) {
        buttonIsEnabled.value = true;
      } else {
        buttonIsEnabled.value = false;
      }
    });

    function buttonClicked() {
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then(() => {
          // A authentication state change listener adds the user to the global app state.
          // See main.ts

          // Go to the page the user wanted to go to (if there was one). Otherwise, go home.
          const path = props.targetPath || '/';
          router.push({
            path,
          });
        })
        .catch(() => {
          notifier.notify(
            'Something is not right. Please check your input and try again.',
            {
              messageType: NotificationMessageType.alert,
            },
          );
        });
    }

    return {
      email,
      password,
      emailInput,
      passwordInput,
      buttonIsEnabled,
      buttonClicked,
    };
  },
});


import { defineComponent } from 'vue';
import TheNotifier from './components/TheNotifier.vue';
import { useNotifier } from './modules/injectUtil';

export default defineComponent({
  components: {
    TheNotifier,
  },
  setup() {
    return {
      notifier: useNotifier(),
    };
  },
});

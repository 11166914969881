
import {
  defineComponent, PropType, ref, watchEffect,
} from 'vue';

export default defineComponent({
  props: {
    menuOptions: {
      type: Object as PropType<string[]>,
      required: true,
    },
    upperCase: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: {
    'update:modelValue': (_index: number) => true,
  },
  setup(props, context) {
    const selectedIndex = ref(props.modelValue);

    watchEffect(() => {
      selectedIndex.value = Math.min(
        selectedIndex.value,
        props.menuOptions.length - 1,
      );
      context.emit('update:modelValue', selectedIndex.value);
    });

    function updateSelectedIndex(index: number) {
      if (index === selectedIndex.value) {
        return;
      }

      selectedIndex.value = index;
      context.emit('update:modelValue', selectedIndex.value);
    }

    return {
      selectedIndex,
      updateSelectedIndex,
    };
  },
});


<template>
  <p class="font-bold text-gray-500 mb-2">
    {{ text }}
  </p>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style></style>

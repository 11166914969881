/* eslint-disable no-unused-vars */
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';

const name = 'highlight';

export default Extension.create({
  name,

  addOptions() {
    return {
      getSelectedTextSegmentId: (): null | string => '',
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(name),
        props: {
          decorations: (state) => {
            const { doc } = state;
            const selectedTextSegmentId = this.options.getSelectedTextSegmentId();
            const decorations: Decoration[] = [];

            doc.descendants((node, pos) => {
              if (node.marks) {
                let isSelected = false;
                node.marks.forEach((mark) => {
                  isSelected = isSelected
                    || mark.attrs.textSegmentId === selectedTextSegmentId;
                });

                if (isSelected) {
                  decorations.push(
                    Decoration.inline(pos, pos + node.nodeSize, {
                      class: 'selected',
                    }),
                  );
                }
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

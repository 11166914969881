
import {
  watchEffect, ref, PropType, defineComponent, computed,
} from 'vue';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  XIcon,
} from '@heroicons/vue/solid';
import BaseCard from '../UI/BaseCard.vue';
import BaseButton from '../UI/BaseButton.vue';
import { TextSegment } from '@/modules/types';
import { useStore } from '@/modules/injectUtil';

export default defineComponent({
  components: {
    BaseCard,
    ArrowUpIcon,
    ArrowDownIcon,
    BaseButton,
    CheckIcon,
    XIcon,
  },
  props: {
    textSegment: {
      type: Object as PropType<TextSegment>,
      required: true,
    },
    selectedTextSegmentId: {
      type: String,
      default: () => null,
      required: false,
    },
    isFirstSuggestion: {
      type: Boolean,
      required: true,
    },
    isLastSuggestion: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    apply: () => true,
    discard: () => true,
    previous: () => true,
    next: () => true,
    newFocus: (_textSegmentId: string) => true,
  },
  setup(props) {
    const store = useStore();
    const showNudge = ref(false);

    setTimeout(() => {
      watchEffect(() => {
        showNudge.value = store.state
          .usingTheApplicationForTheFirstTime as boolean;
      });
    }, 5000);

    const isSelected = computed(() => props.selectedTextSegmentId === props.textSegment._id);

    watchEffect(() => {
      if (isSelected.value) {
        // eslint-disable-next-line no-unused-expressions
        store?.methods.updateState({
          usingTheApplicationForTheFirstTime: false,
        });
      }
    });

    return {
      isSelected,
      showNudge,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_notifier = _resolveComponent("the-notifier")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_notifier, { notifier: _ctx.notifier }, null, 8, ["notifier"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, { name: "fade-route" }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("div", {
              key: route.name,
              class: "bg-gradient-to-bl from-gray-100 to-gray-200 fixed inset-0 overflow-auto"
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}